import { type FileData, createDirectUploadTask, createMultipartUploadV2Task, type UploadConfig } from 'qiniu-js';

export async function uploadFile(file: File, token: string, fname = '') {

	return new Promise((resolve, reject) => {

		const fileData: FileData = {
			type: 'file',
			data: file,
			filename: fname
		};

		const config: UploadConfig = {
			tokenProvider: async () => await token
		}
		const uploadTask =
			createMultipartUploadV2Task(fileData, config);

		// 设置进度回调函数
		uploadTask.onProgress((progress, context) => {
			// 处理进度回调
			console.log(progress, context)
		});

		// 设置完成回调函数
		uploadTask.onComplete((result, context) => {
			console.log(result, context)

			resolve({
				...result,
				type: file.type,
				name: file.name,
			});
			// 处理完成回调
		});

		// 设置错误回调函数
		uploadTask.onError((error, context) => {
			console.log(error)

			throw error.stack
			// 处理错误回调
			reject(error);
		});

		uploadTask.start()
	});
}
